<template>
  <div class="securities">
    <img-div
      class-name=""
      height="320"
      :img-url="require('@/assets/aboutQk/top_banner.png')"
    >
      <div class="container flex flex-hc">
        <div class="p1 mt134">关于我们</div>
        <!-- <div class="desc1 mt26">
          {{ $t("aboutQk.topBannerDesc1") }}
          <br />
          <br />
        </div>
        <div class="desc1">
          {{ $t("aboutQk.topBannerDesc2") }}
        </div> -->
      </div>
    </img-div>
    <div class="team">
      <div class="container">
        <div class="itemTitle">业务与团队</div>
        <div class="desc1 mt28">
          我们是北京清科创业信息咨询有限公司的软件开发服务团队，在软件开发服务方面积累了丰富的经验。可承接各类应用软件的开发服务，在国内已经拥有了众多的成功案例。
          <br />
          <br />
        </div>
        <div class="desc1">
          在项目中，形成了完善的项目管理规范。从需求、设计、开发、测试到交付，可高效的管控进度和质量。
          <br />
          <br />
        </div>
        <div class="desc1">
          清科创业最大的财富就是沉淀了一支优秀的开发队伍，“尽心尽力，尽善尽美”是我们的服务宗旨。项目的交付不是终点而是新起点，我们一直在努力成为“懂你”的合作方。
        </div>
      </div>
    </div>
    <div class="advantage">
      <div class="container">
        <div class="itemTitle">
          {{ $t("aboutQk.advantageTitle") }}
        </div>
        <div class="flex flex-jsb flex-hw">
          <div
            class="advantage-item flex flex-v flex-vc"
            v-for="(item, index) in advantageList"
            :key="index"
          >
            <img :src="item.imgUrl" class="deal-icon" />
            <span class="listItemTitle">{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImgDiv from "@/components/base/ImgDiv.vue";
export default {
  components: {
    ImgDiv,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    advantageList() {
      return this.$t("aboutQk.advantageList") || [];
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.securities {
  background-color: var(--whiteColor);
  .mt28 {
    margin-top: 28px;
  }
  .mt134 {
    margin-top: 134px;
  }
  .about-url {
    padding: 50px 131px 67px;
  }
  .deal-icon {
    width: 120px;
  }
  .a-right {
    width: 14px;
  }
  .url-item {
    cursor: pointer;
  }
  .advantage {
    background-color: var(--bgGray);
    padding-bottom: 105px;
    .itemTitle {
      margin-top: 0;
      padding-top: 85px;
    }
    .advantage-item {
      width: 380px;
      padding: 73px 0 23px;
    }
  }
  .team {
    padding-bottom: 100px;
    .container {
      width: 1155px;
    }
    .itemTitle {
      margin-top: 0;
      padding-top: 85px;
    }
    .desc1 {
      color: var(--blackColor);
    }
  }
}
</style>
